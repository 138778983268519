<template>
  <div>
    <!-- Date Range Selection Form -->
    <div>
      <form @submit.prevent="fetchEvent">
        <label for="start">Start Date:</label>
        <input type="date" v-model="start_date" id="start" required>

        <label for="end">End Date:</label>
        <input type="date" v-model="end_date" id="end" required>

        <button type="submit">Search</button>
        <button type="button" @click="setToday">Today</button>
      </form>
    </div>

    <!-- Error handling -->
    <div v-if="error">{{ error }}</div>

    <!-- Event Details -->
    <div v-if="event">
      <a :href="event.event_link" target="_blank">
        <h2 class="clamped-text">Event: {{ event.summary }}</h2>
      </a>
      <div class="clamped-text">Description: {{ event.description }}</div>
      <div class="clamped-text">Location: {{ event.location }}</div>
      <div class="clamped-text">Start Time: {{ event.dtstart }}</div>

      <h2 v-if="performer_name" class="clamped-text">Performer: {{ performer_name }}</h2>

      <!-- Performer Image and Audio Section -->
      <div class="performer-section">
        <img v-if="spotify_payload && spotify_payload.images && spotify_payload.images.length" :src="spotify_payload.images[0].url" alt="Performer Image" class="performer-image">

        <!-- Audio Section and Controls -->
        <div v-if="spotify_payload && top_tracks.length > 0" class="audio-controls">
          <audio ref="audioPlayer" :src="top_tracks[current_track_idx]?.preview_url" controls></audio>
          <div class="track-navigation">
            <button @click="prevTrack">Previous Track</button>
            <button @click="nextTrack">Next Track</button>
          </div>
        </div>

        <!-- Preview Unavailable Message -->
        <div v-else>
          <p>Preview unavailable for this performer</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

/* Restrict text to one line with ellipsis */
.clamped-text {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Show ellipsis (...) if the text is too long */
  max-width: 100%; /* Ensure the text container doesn't exceed 100% of its parent width */
}

.performer-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.performer-image {
  width: 100px;
  margin-right: 20px;
}

.audio-controls {
  display: flex;
  flex-direction: column;
}

.track-navigation {
  margin-top: 10px;
}

.track-navigation button {
  margin-right: 10px;
}
</style>


<script>
import axios from 'axios'; // Ensure axios is imported

export default {

  data() {
    return {
      event: null,
      performer_name: null,
      spotify_payload: null,
      top_tracks: [],
      current_track_idx: 0,
      preview_url: null,
      error: null,
      page: 0,
      hasInteracted: false, // Track whether user has interacted with the audio
      start_date: '',  // Start date for event query
      end_date: '',    // End date for event query
      performer_idx: 0, // Index to track the current performer
      total_performers: 1, // Total number of performers (default is 1)
      total_pages: 1
    };
  },
  mounted() {
    this.setupAutoplayUnlock();
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    setupAutoplayUnlock() {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const unlockAudio = () => {
        if (audioContext.state === 'suspended') {
          audioContext.resume().then(() => {
            this.hasInteracted = true;
            this.$nextTick(() => this.playAudio()); // Ensure the audioPlayer is available
          });
        } else {
          this.hasInteracted = true;
          this.$nextTick(() => this.playAudio()); // Ensure the audioPlayer is available
        }
      };

      window.addEventListener('click', unlockAudio, { once: true });
      window.addEventListener('touchstart', unlockAudio, { once: true });
      window.addEventListener('touchend', unlockAudio, { once: true });
    },

    handleKeydown(event) {
      switch (event.key) {
        case 'ArrowLeft':
          this.prevPage();
          break;
        case 'ArrowRight':
          this.nextPage();
          break;
        case 'ArrowUp':
          this.prevPerformer();
          break;
        case 'ArrowDown':
          this.nextPerformer();
          break;
      }
    },

    handleSwipe(direction) {
      if (!this.hasInteracted) {
        this.setupAutoplayUnlock(); // Ensure we unlock on first swipe
      }
      
      switch (direction) {
        case 'left':
          this.nextPage();
          break;
        case 'right':
          this.prevPage();
          break;
        case 'up':
          this.prevPerformer();
          break;
        case 'down':
          this.nextPerformer();
          break;
      }
    },

    fetchEvent() {
      if (!this.start_date || !this.end_date) {
        this.error = "Please provide a valid date range.";
        return;
      }

      // Convert start_date and end_date to UTC
      const startDateObj = new Date(this.start_date);  // local time
      const startUTC = new Date(Date.UTC(startDateObj.getFullYear(), startDateObj.getMonth(), startDateObj.getDate())).toISOString().split('T')[0];  // Force UTC

      const endDateObj = new Date(this.end_date);  // local time
      endDateObj.setDate(endDateObj.getDate() + 1);  // Add one day to include the full end date
      endDateObj.setHours(endDateObj.getHours() + 3); // Add three hours to the end date
      const endUTC = new Date(Date.UTC(endDateObj.getFullYear(), endDateObj.getMonth(), endDateObj.getDate(), endDateObj.getHours())).toISOString().split('T')[0];  // Force UTC

      // Send the UTC dates to the backend
      axios.get(`/api/events/${startUTC}/${endUTC}?page=${this.page}&performer_idx=${this.performer_idx}`)
        .then(response => {
          if (response && response.data) {
            const data = response.data;
            this.event = data.event;
            this.performer_name = data.performer_name;
            this.spotify_payload = data.spotify_payload;
            this.top_tracks = [];

            if (this.spotify_payload && this.spotify_payload.top_tracks) {
              this.spotify_payload.top_tracks.forEach(track => {
                if (track.preview_url) {
                  this.top_tracks.push(track);
                }
              });
            }

            this.total_performers = data.total_performers || 1;
            this.total_pages = data.total_pages;
            this.current_track_idx = 0;

            if (this.top_tracks.length > 0 && this.hasInteracted) {
              this.$nextTick(() => {
                this.playAudio();
              });
            }
          }
        })
        .catch(error => {
          this.error = error.response?.data?.error || 'An error occurred';
        });
    },

    setToday() {
      const today = new Date().toISOString().substr(0, 10);
      this.start_date = today;
      this.end_date = today;
    },

    nextPage() {
      this.page++;

      if (this.page >= this.total_pages) {
        console.log(`${this.page} exceeds total ${this.total_pages}`)
        this.page = 0;
      }
      this.performer_idx = 0;
      this.fetchEvent();
    },
    prevPage() {
      if (this.page > 0) {
        this.page--;
        this.performer_idx = 0;
        this.fetchEvent();
      }
    },
    nextPerformer() {
      this.performer_idx++;
      if (this.performer_idx >= this.total_performers) {
        this.performer_idx = 0;
      }
      this.fetchEvent();
    },
    prevPerformer() {
      this.performer_idx--;
      if (this.performer_idx < 0) {
        this.performer_idx = this.total_performers - 1;
      }
      this.fetchEvent();
    },
    nextTrack() {
      this.current_track_idx++;
      if (this.current_track_idx >= this.top_tracks.length) {
        this.current_track_idx = 0;
      }
      this.loadTrack();
    },
    prevTrack() {
      this.current_track_idx--;
      if (this.current_track_idx < 0) {
        this.current_track_idx = this.top_tracks.length - 1;
      }
      this.loadTrack();
    },
    loadTrack() {
      this.$nextTick(() => {
        const audioPlayer = this.$refs.audioPlayer;
        if (audioPlayer) {
          audioPlayer.src = this.top_tracks[this.current_track_idx].preview_url;
          this.playAudio(); // Play the audio after loading the track
        }
      });
    },
    playAudio() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.play().catch(error => {
          console.error("Autoplay failed:", error);
        });
      }
    },
  }
};
</script>

