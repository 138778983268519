import Hammer from 'hammerjs';

export default {
  mounted(el, binding) {
    console.log("Swipe directive initialized on element:", el);
    const hammer = new Hammer(el);

    // Prevent vertical scrolling and pull-to-refresh while allowing button presses
    el.addEventListener('touchmove', (event) => {
      if (event.cancelable) {
        // Only prevent scrolling if not on an input, button, or select element
        const target = event.target;
        const isInteractiveElement = 
          target.tagName === 'INPUT' || 
          target.tagName === 'BUTTON' || 
          target.tagName === 'SELECT' || 
          target.tagName === 'TEXTAREA';

        if (!isInteractiveElement) {
          event.preventDefault(); // Prevent touch-driven scroll for non-interactive elements
        }
      }
    }, { passive: false });

    // Add swipe event listeners and enable all swipe directions
    hammer.get('swipe').set({ direction: Hammer.DIRECTION_ALL });

    // Prevent default actions and handle swipeleft
    hammer.on('swipeleft', (event) => {
      event.preventDefault(); // Prevent default browser action
      console.log("Swiped left");
      binding.value('left');
    });

    // Prevent default actions and handle swiperight
    hammer.on('swiperight', (event) => {
      event.preventDefault(); // Prevent default browser action
      console.log("Swiped right");
      binding.value('right');
    });

    // Prevent default actions and handle swipeup
    hammer.on('swipeup', (event) => {
      event.preventDefault(); // Prevent default browser action
      console.log("Swiped up");
      binding.value('up');
    });

    // Prevent default actions and handle swipedown
    hammer.on('swipedown', (event) => {
      event.preventDefault(); // Prevent default browser action
      console.log("Swiped down");
      binding.value('down');
    });

    // Disable pull-to-refresh and overscroll behavior on the body element
    document.body.style.overscrollBehavior = 'none'; // Prevent pull-to-refresh and overscroll
  },
};
