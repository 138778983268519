<template>
  <div id="app" v-swipe="handleSwipe" class="full-page">
    <EventDetails ref="eventDetails" />
  </div>
</template>

<script>
import EventDetails from './components/EventDetails.vue';
import swipe from './v-swipe.js'; // Import the swipe directive

export default {
  name: 'App',
  components: {
    EventDetails,
  },
  directives: {
    swipe, // Register the directive here
  },
  methods: {
    handleSwipe(direction) {
      const eventDetails = this.$refs.eventDetails;
      if (eventDetails) {
        switch (direction) {
          case 'left':
            eventDetails.nextPage();
            break;
          case 'right':
            eventDetails.prevPage();
            break;
          case 'up':
            eventDetails.prevPerformer();
            break;
          case 'down':
            eventDetails.nextPerformer();
            break;
        }
      }
    },
  },
};
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
}

.full-page {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
